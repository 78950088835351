import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleService {
  public toggleEvent = new Subject();
  public isMenuClicked = new BehaviorSubject<boolean>(false);
  constructor() {}

  public toggle(show: boolean) {
    this.toggleEvent.next(show);
  }

  public getToggle() {
    return this.toggleEvent.asObservable();
  }
}
